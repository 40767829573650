import useCmsTranslation from "@/hooks/useCmsTranslation";
import { valueFromStoreSetting } from "@/services/ceSettings/ceSettingsService";
import { useAppSelector } from "@/store/store";
import { StoreSetting } from "@/types/ceSettings/ceSettings";
import { CESeparator } from "@/types/content-elements";
import dynamic from "next/dynamic";
import { ContentElementSeparatorStoreSetting } from "../../../types/ceSettings/ceSettings";
import React from "react";

const ShapeDivider = dynamic(() => import("./shapes/shapeDivider"));
const ShapeSkewed = dynamic(() => import("./shapes/shapeSkewed"));
const ShapeTriangle = dynamic(() => import("./shapes/shapeTriangle"));
const ShapeWaves = dynamic(() => import("./shapes/shapeWaves"));
const ShapeWavesOpacity = dynamic(() => import("./shapes/shapeWavesOpacity"));

export interface SeparatorContentElementProps {
  content: CESeparator;
  position: number;
  ceSettings?: ContentElementSeparatorStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export default React.memo(function SeparatorContentElement(
  props: SeparatorContentElementProps
) {
  const tCms = useCmsTranslation();
  const { content, ceSettings } = props;
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  /**
   * Extended function for contentElementBGColor to get a default value instead of "unset".
   * @param {string} selectedColor as string value
   * @param {object} settings props.settings from CE
   * @returns {string} Color as hex value. Default value if the selection does not return a result
   */
  function getSeparatorConfigColor(selectedColor: StoreSetting) {
    const idOfCSSVar = valueFromStoreSetting(selectedColor);
    return idOfCSSVar ? `var(--str-color-${idOfCSSVar})` : "#fff";
  }

  /**
   * Returns the set height in px as int value
   * @param {object} content props.content from CE
   * @param {object} settings props.settings from CE
   * @returns {int} Height as px value
   */
  function getShapeHeight(content: CESeparator, settings: any) {
    if (settings === null) {
      return null;
    }
    const selection = content.cfgShapeHeight;

    if (selection === "shape-height-small") {
      return settings?.shapeHeight?.small;
    } else if (selection === "shape-height-large") {
      return settings?.shapeHeight?.large;
    } else {
      return settings?.shapeHeight?.medium;
    }
  }

  const topColor = getSeparatorConfigColor(content.cfgStrBgColorTop);
  const bottomColor = getSeparatorConfigColor(content.cfgStrBgColorBottom);
  const shapeFlip = content.cfgShapeFlip;
  const shapeHeight = getShapeHeight(content, ceSettings);

  const shapeProps = {
    topColor,
    bottomColor,
    shapeFlip,
    shapeHeight,
  };

  const separatorComponents = {
    "shape-triangle": <ShapeTriangle {...shapeProps} />,
    "shape-skewed": <ShapeSkewed {...shapeProps} />,
    "shape-divider": <ShapeDivider {...shapeProps} />,
    "shape-waves": <ShapeWaves {...shapeProps} />,
    "shape-waves-opacity": <ShapeWavesOpacity {...shapeProps} />,
  };

  if (ceSettings !== null) {
    return content.cfgSeparatorTemplate !== null ? (
      separatorComponents[content.cfgSeparatorTemplate]
    ) : editView ? (
      <>{tCms("separatorTemplateError")}</>
    ) : (
      <></>
    );
  } else {
    return null;
  }
});
